<script setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BCanvas from '@/components/BCanvas'
import MessageBox from '@/components/MessageBox'
import ModalPanel from '@/components/ModalPanel'
import ModalGallery from '@/components/ModalGallery'
import ModalRestoration from '@/components/ModalRestoration'
import ModalAbout from '@/components/ModalAbout'
const log = console.log; log()

const route = useRoute()
const router = useRouter()

const menuOpen = ref(false)
const toggleMenu = () => menuOpen.value = !menuOpen.value
const closeMenu = () => {
  menuOpen.value = false
  router.replace('/')
}
const meshName = ref('')
const isLoading = ref(true)
const selectedMesh = value => meshName.value = value
const isLoaded = value => isLoading.value = !value
const showModal = ref(false)
const modalType = ref(null)

const openModal = async page => {
  closeMenu()
  modalType.value = page
  showModal.value = true
  router.replace(`/${page}`)
}

watch(() => route.path, (newPath) => {
  if (newPath === '/gallery') {
    openModal('gallery')
  } else if (newPath === '/restoration') {
    openModal('restoration')
  } else if (newPath === '/about') {
    openModal('about')
  } else {
    showModal.value = false
    modalType.value = null
  }
})

if (route.path === '/gallery') {
  openModal('gallery')
} else if (route.path === '/restoration') {
  openModal('restoration')
} else if (route.path === '/about') {
  openModal('about')
}
</script>

<template>
<div class="wrapper" @click="closeMenu">
  <button
    class="menu-button"
    v-if="!showModal"
    @click.stop="toggleMenu"
    >
    <v-icon
      :icon="menuOpen ? 'mdi-close' : 'mdi-menu'"
      />
  </button>
  <v-menu
    v-model="menuOpen"
    v-if="!showModal"
    class="menu-list"
    >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="menu-activator"
        />
    </template>
    <ul @click.stop>
      <li>
        <a href="#" @click.prevent="openModal('about')">About</a>
      </li>
      <li>
        <a href="#" @click.prevent="openModal('gallery')">Gallery</a>
      </li>
      <li>
        <a href="#" @click.prevent="openModal('restoration')">Restoration</a>
      </li>
      <li>
        <a href="https://www.instagram.com/msnrhnd/" target="_blank">
          <v-icon icon="mdi-instagram" />
        </a>
      </li>
    </ul>
  </v-menu>
  <v-progress-circular
    v-if="isLoading"
    indeterminate
    color="gray"
    />
  <BCanvas
    @selectedMesh="selectedMesh"
    @isLoaded="isLoaded"
    />
  <a
    href="https://docs.google.com/forms/d/e/1FAIpQLSeHRD1XpLYrviapswBoKz6Y9s0LL9_QblPerRm5yg4iTus94A/viewform?usp=sf_link"
    >
    <v-btn
      class="enquete"
      variant="elevated"
      color="primary"
      >
      展示アンケート（Googleフォーム）<v-icon icon="mdi-link" />
    </v-btn>
  </a>
  <MessageBox
    :meshName="meshName"
    />
  <ModalPanel v-model="showModal">
    <template v-if="modalType==='about'">
      <ModalAbout />
    </template>
    <template v-else-if="modalType==='gallery'">
      <ModalGallery />
    </template>
    <template v-else-if="modalType==='restoration'">
      <ModalRestoration />
    </template>
  </ModalPanel>
</div>
</template>

<style>
html {
  font-size: 16px;
  overscroll-behavior: none;
}
.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #888;
  overflow: hidden;
}
button.menu-button {
  position: absolute;
  top: .4rem;
  right: .8rem;
  display: block;
  color: white;
  text-shadow: 1px 1px 1px black;
  background: transparent;
  font-size: 2rem;
}
button.enquete {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.menu-list {
  direction: rtl !important;
  position: absolute !important;
  top: 2.4rem !important;
  padding: .8rem;
}
.menu-list li {
  font-size: 2.4rem;
  list-style: none;
  color: white;
  text-shadow: 1px 1px 1px black;
  text-align: right;
  line-height: 1.8;
}
.menu-list li a {
  color: white;
  text-decoration: none;
}
.v-progress-circular {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.menu-activator {
  background: transparent;
  border: none;
  cursor: pointer;
}
</style>
