<template>
<canvas />
</template>

<script>
import * as BABYLON from '@babylonjs/core'
import '@babylonjs/loaders'
import '@babylonjs/core/Debug/debugLayer'
import '@babylonjs/inspector'

import axios from 'axios'
const log = console.log; log()
export default {
  data() {
    return {
      engine: null,
      scene: null,
      camera: null,
      selected: null,
      messages: ['ホーホー！', 'いまギャグを考えています', '……',
                 'ちょっと待ってくださいね', '見てくれてありがとう',
                 'ホッホー！', '面白いことを考えてます', 'まだ話すこと決めてない',
                 'アンケートに答えてみてね', '僕に興味がありますか？',
                ],
      nextMessage: [],
      bubblePlane: null,
      bubbleTexture: null,
    }
  },
  methods: {
    fetchChatWithRetry (maxRetries = 3) {
      let retries = 0;
      function attemptRequest() {
        return axios.post('/api/chat', {
          messages: [{ role: 'user', content: `フクロウがしゃべりそうな不条理なギャグを10個json形式の配列['a', 'b', 'c',...]として出力してください。` }]
        })
          .then(response => {
            try {
              const parsedData = JSON.parse(response.data.replace(/^```json/, '').replace(/```$/g, '').trim());
              return parsedData; // JSON.parse成功
            } catch (error) {
              if (retries < maxRetries) {
                retries++;
                console.warn(`JSON parse failed. Retrying... (${retries}/${maxRetries})`);
                return attemptRequest(); // リトライ
              } else {
                throw new Error('Max retries reached. Failed to parse JSON.');
              }
            }
          });
      }
      
      return attemptRequest();
    },
    async loadModel(meshName, position, scene) {
      const light = new BABYLON.DirectionalLight(
        'dirLight', new BABYLON.Vector3(0, -10, -10), this.scene)
      light.position = new BABYLON.Vector3(10, 10, 100)
      light.intensity = 1.2
      light.shadowMinZ = 1;
      light.shadowMaxZ = 100;
      const shadowGenerator = new BABYLON.ShadowGenerator(2048, light)
      shadowGenerator.useBlurExponentialShadowMap = true
      shadowGenerator.blurScale = 2.0;
      shadowGenerator.setDarkness(0.2); // 影の濃さを調整
      const result = await BABYLON.SceneLoader.ImportMeshAsync(
        meshName, '/models/', 'Room.glb', scene
      )
      result.animationGroups.forEach(group => group.play(true))
      result.meshes.forEach(mesh => {
        mesh.isPickable = true
        mesh.name = mesh.name.split(/[._]/)[0]
        switch (mesh.name) {
          case 'Owl':
            mesh.actionManager = new BABYLON.ActionManager(scene)
            mesh.actionManager.registerAction(
              new BABYLON.ExecuteCodeAction(
                BABYLON.ActionManager.OnPickTrigger, () => {
                  this.showSpeechBubble(mesh)
                  this.$emit('selectedMesh', mesh.name)
                }))
            shadowGenerator.addShadowCaster(mesh)
            break
          case 'Shelf':
            mesh.receiveShadows = true
            shadowGenerator.addShadowCaster(mesh)
            break
          default:
            mesh.actionManager = new BABYLON.ActionManager(scene)
            mesh.actionManager.registerAction(
              new BABYLON.ExecuteCodeAction(
                BABYLON.ActionManager.OnPickTrigger, () => {
                  this.$emit('selectedMesh', mesh.name)
                }))
            shadowGenerator.addShadowCaster(mesh)
            break
        }
      })
      const model = result.meshes[0]
      model.position = new BABYLON.Vector3(...position)
      return model
    },

    async showSpeechBubble(mesh) {
      if (this.messages.length === 0) {
        this.messages = this.nextMessages
      } else if (this.messages.length === 10) {
        this.fetchChatWithRetry()
          .then(parsedMessages => {
            this.nextMessages = parsedMessages
          })
          .catch(error => {
            console.error('Error:', error)
          })
      }
      if (this.bubblePlane) {
        this.bubblePlane.dispose()
        this.bubblePlane = null
        return;
      }

      // messagesからランダムで一つの要素をとりだす. 配列を短くする
      const message = this.messages.splice(Math.floor(Math.random() * this.messages.length), 1)[0]
           
      // let message = this.messages[
      //   Math.floor(Math.random() * this.messages.length)]

      // メッセージを10文字ごとに分割して配列にする
      const maxCharsPerLine = 10
      let lines = []
      for (let i = 0; i < message.length; i += maxCharsPerLine) {
        lines.push(message.slice(i, i + maxCharsPerLine))
      }
      
      // マージンの設定
      const margin = 20  // 上下左右のマージン
      const lineHeight = 100 // 1行あたりの高さ
      
      // テキストの行数に基づいてバブルの高さを調整
      const bubbleHeight = lineHeight * lines.length + margin * 2
      
      // 幅は最大の文字列長に基づく
      const tempTexture = new BABYLON.DynamicTexture('tempTexture', { width: 512, height: 512 }, this.scene)
      const ctx = tempTexture.getContext()
      ctx.font = 'normal 80px Arial'

      // 最長の行を計測して幅を決定
      let maxWidth = 0
      lines.forEach(line => {
        const width = ctx.measureText(line).width
        if (width > maxWidth) {
          maxWidth = width
        }
      })
      const bubbleWidth = maxWidth + margin * 2; // 左右にマージンを追加

      // バブルテクスチャの作成
      this.bubbleTexture = new BABYLON.DynamicTexture(
        'bubbleTexture', {
          width: bubbleWidth, height: bubbleHeight
        }, this.scene)
      const bubbleCtx = this.bubbleTexture.getContext()
      bubbleCtx.fillStyle = 'white'
      bubbleCtx.fillRect(0, 0, bubbleWidth, bubbleHeight)
      bubbleCtx.font = 'normal 80px Arial'
      bubbleCtx.fillStyle = 'black'

      // 各行を描画する。マージンを考慮してテキストの描画開始位置を調整
      lines.forEach((line, index) => {
        bubbleCtx.fillText(line, margin, margin + 80 + index * lineHeight)
      })

      this.bubbleTexture.update()

      // バブルのメッシュを作成
      this.bubblePlane = BABYLON.MeshBuilder.CreatePlane('bubble', {
        width: bubbleWidth / 10, height: bubbleHeight / 10
      }, this.scene)

      this.bubblePlane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL
      this.bubblePlane.position =
        mesh.position.clone().add(new BABYLON.Vector3(0, 160, 60))

      const bubbleMaterial =
            new BABYLON.StandardMaterial('bubbleMaterial', this.scene)
      bubbleMaterial.diffuseTexture = this.bubbleTexture
      this.bubblePlane.material = bubbleMaterial
    },
  },
  async mounted() {
    this.fetchChatWithRetry()
      .then(parsedMessages => {
        this.messages = parsedMessages
      })
      .catch(error => {
        console.error('Error:', error)
      })
    const canvas = this.$el
    this.engine = new BABYLON.Engine(canvas, true)
    this.scene = new BABYLON.Scene(this.engine)
    this.scene.clearColor = new BABYLON.Color4(1, 1, 1, 1)
    this.camera = new BABYLON.ArcRotateCamera(
      'camera', Math.PI / 2, Math.PI / 2, 0,
      new BABYLON.Vector3(0, 80, 0), this.scene
    )
    this.camera.lowerAlphaLimit = 0
    this.camera.upperAlphaLimit = Math.PI
    this.camera.upperBetaLimit = Math.PI / 2
    this.camera.lowerRadiusLimit = 500
    this.camera.upperRadiusLimit = 2000
    this.camera.attachControl(canvas, true)
    await this.loadModel('', [0, 0, 0], this.scene)
    new BABYLON.LensRenderingPipeline('lensEffects', {
      chromatic_aberration: 8,
      grain_amount: 4,
      distortion: 1,
      // dof_threshold: 100,
      // edge_blur: 0.2,
      // dof_pentagon: true,
      // dof_focus_distance: 38,
      // dof_aperture: 1.2,
      // dof_gain: 0.2,
      // dof_darken: 0.25
    }, this.scene, 0.0)
    
    this.scene.postProcessRenderPipelineManager
      .attachCamerasToRenderPipeline('lensEffects', this.camera)
    this.engine.runRenderLoop(() => this.scene.render())
    window.addEventListener('resize', () => this.engine.resize())
    this.$emit('isLoaded', true)
    // this.scene.debugLayer.show()
  }
}
</script>

<style>
canvas {
  width: 100%;
  height: 100%;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>
