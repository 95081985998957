<script setup>
import { ref, defineProps, watch } from 'vue'
import axios from 'axios'
const log = console.log; log()

const props = defineProps({
  meshName: {
    type: String,
    default: ''
  }
})
const message = ref({})
const things = ref({})

axios.get('/api/getThings').then(response => {
  things.value = response.data.reduce((res, cur) => {
    res[cur.title] = cur
    return res
  }, {})
})

watch(() => props.meshName, (newMeshName) => {
  message.value = things.value[newMeshName] || {}
})

const formatDate = date => {
  const d = new Date(date)
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  return `${year}.${month}`
}
</script>

<template>
<div id="message-box" :class="message.type">
  <h1>{{ message.title }}</h1>
  <template v-if="message.type === 'sculpture'">
    <p class="dimension">
      {{ `H:${message.height}×W:${message.width}×D:${message.depth} mm` }}
    </p>
    <p class="weight">
      {{ `Wt:approx. ${message.weight} g` }}
    </p>
    <p class="material">
      {{ message.material }}
    </p>
    <p class="manufacturing-date">
      {{ formatDate(message.manufacturing_date) }}
    </p>
  </template>
  <p class="description">
    {{ message.description }}
  </p>
</div>
</template>

<style>
#message-box {
  padding: 2vw;
}
#message-box.sculpture {
  position: fixed;         /* 固定位置にする */
  left: 0;                 /* 左端に配置 */
  bottom: 0;
  transform-origin: 0 100%;
  transform: scale(.5);
}
#message-box, .modal {
  position: absolute;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 9vmin;
  line-height: 1;
  top: 0;
  left: 0;
  pointer-events: none;
  font-feature-settings: 'palt';
  font-family: 'Roboto', sans-serif;
}
h1 {
  font-style: italic;
  text-indent: -.12em;
  font-variant-caps: small-caps;
}
#message-box p {
  font-size: 1.2em;
  margin-bottom: 0;
  line-height: 1.2;
}
</style>
